// write css related to font

.fs-8 {
    font-size: 8px !important;
}

.fs-9 {
    font-size: 9px !important;
}

.fs-10 {
    font-size: 10px !important;
}

.fs-11 {
    font-size: 11px !important;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-13 {
    font-size: 13px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-15 {
    font-size: 15px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-17 {
    font-size: 17px !important;
}

.fs-18 {
    font-size: 18px !important;
}

.fs-20 {
    font-size: 20px !important;
}

.fs-21 {
    font-size: 21px !important;
}

.fs-22 {
    font-size: 22px !important;
}

.fs-24 {
    font-size: 24px !important;
}

.fs-25 {
    font-size: 25px !important;
}

.fs-26 {
    font-size: 26px !important;
}

.fs-28 {
    font-size: 28px !important;
}

.fs-30 {
    font-size: 30px !important;
}

.fs-32 {
    font-size: 32px !important;
}

.fs-36 {
    font-size: 36px !important;
}

.fs-31 {
    font-size: 31px !important;
}

.fs-44 {
    font-size: 44px !important;
}

.fs-45 {
    font-size: 45px !important;
}

.fs-48 {
    font-size: 48px !important;
}

.fs-56 {
    font-size: 56px !important;
}

.fs-58 {
    font-size: 58px !important;
}

.fs-60 {
    font-size: 60px !important;
}

.fs-62 {
    font-size: 62px !important;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}
