// write responsive css

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .book-venue-width {
        width: 80% !important;
    }
}

@media only screen and (max-width: 767px) {
    .book-venue-width {
        width: 75% !important;
    }
    .book-venue-bottom {
        bottom: 57% !important;
    }
}
